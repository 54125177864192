import React from 'react';
import MainLayout from '../../layouts/main';
import { Typography } from '@material-ui/core';
import { SEO } from '../../components/SEO';
import PageHead from '../../components/PageHead';
import {
  FeatureGrid,
  AuthorAutomation,
  AuthorGroups,
  AuthorSync
} from '../../components/FeatureGrid';
import { DemoButton } from '../../components/DemoButton';

export default () => {
  return (
    <MainLayout>
      <div>
        <SEO
          pathname="/product/heatmaps/"
          title="Author Insights for Affiliate Publishers"
          description="See which authors are producing the highest revenue and EPC articles, and learn what sets them and their content apart."
        />
        <PageHead>
          <Typography
            variant="h3"
            gutterBottom
            component="h1"
            style={{
              maxWidth: '630px',
              margin: '0 auto 36px',
              fontWeight: 900
            }}
          >
            Automatically track your authors' affiliate content performance
          </Typography>
          <Typography
            variant="body1"
            color="textSecondary"
            style={{ maxWidth: '510px', margin: '24px auto 12px' }}
          >
            See which authors are producing the highest revenue and EPC
            articles, and learn what sets them and their content apart.
          </Typography>
          <div style={{ textAlign: 'center', margin: '24px auto 0' }}>
            <DemoButton variant="contained" />
          </div>
          <img
            src="/images/features/author-reporting.png"
            style={{
              width: '80%',
              maxWidth: '700px',
              margin: '12px auto',
              display: 'block'
            }}
            alt="Affiliate author insights"
          />
        </PageHead>
        <FeatureGrid>
          <AuthorAutomation />
          <AuthorGroups />
          <AuthorSync />
        </FeatureGrid>
        <div style={{ maxWidth: '900px', margin: '96px auto 64px' }}>
          <img
            src="/images/features/author-filtering.png"
            style={{
              width: '80%',
              maxWidth: '500px',
              borderRadius: '8px',
              boxShadow: '0px 0px 30px rgba(0, 0, 0, 0.1)',
              transform: 'rotate(-2deg)',
              margin: '48px auto',
              display: 'block'
            }}
            alt="Affiliate author reporting"
          />
          <Typography
            variant="h5"
            gutterBottom
            component="h2"
            style={{
              textAlign: 'center',
              fontWeight: 700
            }}
          >
            Your best authors are doing something different.
          </Typography>
          <Typography
            variant="body1"
            component="p"
            paragraph
            style={{
              maxWidth: '510px',
              margin: '0 auto',
              textAlign: 'center'
            }}
          >
            Find out what it is, and replicate it across your team.
          </Typography>
          <div style={{ textAlign: 'center', margin: '24px auto 0' }}>
            <DemoButton variant="contained" />
          </div>
        </div>
      </div>
    </MainLayout>
  );
};
